import { createContext, useState } from "react";

// Docu: https://www.codementor.io/@sambhavgore/an-example-use-context-and-hooks-to-share-state-between-different-components-sgop6lnrd
// example code: https://codesandbox.io/s/5xw1jl721l?from-embed

/**
 * Uses context because each vin search box needs to manage its own state (for show/hide error alert)
 */
export const VinSearchContext = createContext(null);

export const VinSearchCtxProvider = (props) => {
  const { children, routeToSingleReport = false, gtagLocation = undefined } = props;
  const [errorMsg, setErrorMsg] = useState(null);
  const [status, setStatus] = useState(null);
  const [uuId, setUuId] = useState("");
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <VinSearchContext.Provider
      value={{
        status,
        setStatus,
        errorMsg,
        setErrorMsg,
        uuId,
        setUuId,
        hasFocus,
        setHasFocus,
        routeToSingleReport,
        gtagLocation,
      }}
    >
      {children}
    </VinSearchContext.Provider>
  );
};
